import React from 'react'
import fleetLogo from './assets/fleet-logo-white.svg'
import './App.css'

const StaticAssetsUrl = 'https://static.movewithfleet.com/mobile'

function App() {
  return (
    <div className='app'>
      <header className='app-header'>
        <img src={fleetLogo} alt='fleet-logo' />
      </header>
      <div className='content'>
        <div className='banner'>
          <h2>Download Our Fleet Employee App</h2>
          <img
            className='banner-img'
            src={`${StaticAssetsUrl}/misc/employee-banner.png`}
            alt='Download our fleet employee app'
          />
        </div>
        <div className='logo-container'>
          <a
            className='logo'
            href='https://play.google.com/store/apps/details?id=com.fleet_mobile&hl=en_CA&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              alt='Get it on Google Play'
              src={`${StaticAssetsUrl}/misc/googleplay.png`}
            />
          </a>
          <a
            className='logo apple-logo'
            href='https://apps.apple.com/us/app/move-with-fleet/id1575305413?itsct=apps_box_badge&amp;itscg=30200'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={`${StaticAssetsUrl}/misc/appstore.png`}
              alt='Download on the App Store'
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default App
